$teal: #038d92;
$white: #fff;
$grey10: #ced4da;
$grey80: #4a5568;
$grey50: #8c8f95;
$grey100: #1a202c;
$breakMobile: 800px;
@mixin transition {
	transition: all 0.2s ease;
}
