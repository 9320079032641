.button {
	font-size: 16px;
	line-height: 1;
	border-radius: 3px;
	padding: 0.7rem 1rem;
	padding-bottom: 0.6rem;
	border: 0px;
	cursor: pointer;
	appearance: none;
	background: transparent;
	text-decoration: none;
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
	&:hover {
		transition: all 0.2s ease;
		transform: translateY(1px);
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	}
}

.button-primary {
	background: $teal;
	color: #fff;
	font-weight: bold;
	&:hover {
		background: $grey80;
	}
}

.button-secondary {
	background: $grey50;
	color: #fff;
	font-weight: bold;
	&:hover {
		background: $grey80;
	}
}

body .react-confirm-alert-button-group > button {
	font-size: 16px;
	line-height: 1;
	border-radius: 3px;
	padding: 0.7rem 1rem;
	padding-bottom: 0.6rem;
	border: 0px;
	cursor: pointer;
	appearance: none;
	background: transparent;
	text-decoration: none;
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
	&:hover {
		transition: all 0.2s ease;
		transform: translateY(1px);
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	}
	background: $grey50;
	color: #fff;
	font-weight: bold;
	&:hover {
		background: $grey80;
	}
}

.button-danger {
	background: #eee;
	font-weight: bold;
	color: #cb2431;
}
.button-google {
	color: $grey80;
	font-weight: bold;
	background: #fff;
	border-radius: 5px;
	display: flex;
	width: 100%;

	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
	img {
		margin-top: -1px;
		margin-right: 1rem;
		height: 1.2rem;
	}
	&:hover {
		background: #ccc;
	}
}

.button-facebook {
	color: #fff;
	margin-top: 1rem;
	font-weight: bold;
	background: #3b5999;
	border-radius: 5px;
	display: flex;
	width: 100%;

	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
	img {
		margin-top: -1px;
		margin-right: 1rem;
		height: 1.2rem;
	}
	&:hover {
		background: #ccc;
	}
}

.button-transparent {
	font-weight: bold;
	color: $teal;
	appearance: none;
	background: transparent;
	border: 0px transparent;
	border-radius: 0;
	font-size: 14px;
	border-radius: 3px;
	cursor: pointer;
	padding: 0.25rem 0.5rem;
	transition: all 0.2s ease;
	&:hover {
		transition: all 0.2s ease;

		background: #eee;
	}
}
