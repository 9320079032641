@import '../../styles/variables.scss';

#Splash {
	.inner {
		.splashimg {
			max-width: 80%;
			height: auto;
			float: right;
		}
		max-width: 1000px;
		margin: 0 auto;
		h1 {
			color: $grey80;

			font-size: 70px;
			.grey {
				color: $teal;
			}
		}
		.text {
			font-size: 24px;
			line-height: 38px;
		}
		.btns {
			margin-top: 3rem;
			a {
				margin-right: 1rem;
				font-size: 18px;
			}

			display: flex;
			@media (max-width: $breakMobile) {
				display: block;
				a {
					margin-right: 0;
					margin-bottom: 1rem;
				}
			}
		}
	}
	display: flex;
	align-items: center;
	min-height: calc(100vh - 100px);
	@media (max-width: $breakMobile) {
		padding: 2rem;
		.inner {
			h1 {
				font-size: 55px;
			}
		}

		.inner .splashimg {
			float: none;
			margin: 0 auto;
			display: block;
		}
	}
}
