@import "../../styles/variables.scss";

.Content {
  padding-top: 75px;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  align-items: center;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  @media (max-width: $breakMobile) {
    padding: 1rem;
    padding-top: 75px;
  }
}
