.formgroup {
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea,
option {
	font-family: 'Lato', sans-serif;
	appearance: none;
	border: 1px solid $grey10;
	border-radius: 3px;
	padding: 0.5rem 1rem;
	font-size: 16px;
	width: 100%;
	&.big {
		font-size: 24px;
	}
}

input[type='sumbit'] {
}

select {
	background-image: url(../images/arrow.svg);
	background-repeat: no-repeat;
	background-position: center right 10px;
}

label {
	font-size: 12px;
	margin-bottom: 0.5rem;
	display: block;
	color: $grey50;
	font-weight: bold;
}

.formgrid {
	grid-template-columns: 1fr 1fr;
	display: grid;
	grid-gap: 2rem;
	.formgroup {
		&.span2 {
			grid-column: 1 / span 2;
		}
		@media (max-width: $breakMobile) {
			margin-bottom: 2rem;
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
	}
	@media (max-width: $breakMobile) {
		display: block;
	}
}

input[type='file'] {
}

.uploader {
	border: 1px solid #eee;
	position: relative;
	background-color: #eee;
	border-radius: 5px;
	background-size: cover;
	background-position: center;
	height: 200px;
	width: 200px;
	overflow: hidden;
	.image-placerholder {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		svg {
			margin-bottom: 1rem;
		}
	}
	.progress {
		height: 10px;
		background: $teal;
		position: absolute;
		bottom: 0;
	}
	.trash-image {
		height: 24px;
		width: 24px;
		background: rgba(0, 0, 0, 0.5);
		padding: 5px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 8px;
		top: 8px;
		position: absolute;
		&:hover {
			background: $teal;
			cursor: pointer;
		}
	}
}
