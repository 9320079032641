@import "../../styles/variables.scss";

#Login {
  width: 100%;
  max-width: 300px;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
}

.or {
  padding: 10px;
  text-align: Center;
  display: block;
}

.error {
  color: red;
  padding: 2rem 0rem;
}

@media (max-width: $breakMobile) {
  #Login {
    margin-top: 50px;
  }
}

.forgot {
  font-size: 12px;
  color: $grey50;
  margin-bottom: 1rem;
}
