* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: $grey80;
  background: #eee;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.contained {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  &.toolbar {
    margin: 0 50px 0px 300px;
  }
}

.box {
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      font-size: 12px;
      color: $grey50;
      text-align: left;
    }
  }
  tr {
    td,
    th {
      text-align: left;

      border-bottom: 1px solid #eee;
      padding: 1rem;
    }
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p1 {
  padding: 8px;
}
.p2 {
  padding: 16px;
}
.p3 {
  padding: 32px;
}
.mb1 {
  margin-bottom: 8px;
}
.mb2 {
  margin-bottom: 16px;
}
.mb3 {
  margin-bottom: 32px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#Footer {
  font-size: 12px;
  color: $grey50;
  padding: 1rem;
  display: flex;
  justify-content: center;
  a {
    color: $grey50;
    text-decoration: none;

    &.active {
      text-decoration: underline;
    }
  }
}
