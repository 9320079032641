@import "../../styles/variables.scss";

.Toolbar {
  background: #fff;
  padding: 2rem;
  position: fixed;
  width: 250px;
  top: 50px;
  bottom: 0;
  overflow-y: auto;
  left: 0;
  z-index: 999999;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      border-bottom: 1px solid #eee;
      padding: 1rem 0;
      .quest-delete,
      .quest-move {
        float: right;
        display: none;
        background: transparent;
        border: none;
        &:hover {
          opacity: 0.5;
        }
      }

      ul li {
        border-bottom: 0px solid transparent;
        &:hover {
          .quest-delete,
          .quest-move {
            display: block;
          }
        }
      }
      a {
        color: $grey80;
        font-weight: 300;

        text-decoration: none;
        &.active {
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: $breakMobile) {
    transition: all 0.3s ease;
    right: 0;
    left: auto;
    transform: translateX(100%);
    &.visible {
      transition: all 0.3s ease;

      transform: translateX(0%);
    }
  }
}
.hide-toolbar,
.show-toolbar {
  padding: 0;
  display: none;
  @media (max-width: $breakMobile) {
    display: block;
  }
}

.show-toolbar {
  float: right;
  margin-top: 1rem;
}
.hide-toolbar {
  position: absolute;
  top: 1rem;
  right: 1rem;
  float: right;
  transform: rotate(180deg);
}

#CreateEdit {
  align-self: flex-start;
  width: 100%;
  .content {
    margin-left: 250px;
    padding-left: 10%;
    padding-right: 10%;
    margin-right: 0;
    @media (max-width: $breakMobile) {
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .copyUrl {
    overflow: hidden;
    display: flex;
    line-break: loose;

    a {
      margin-right: 1rem;
    }
    svg {
      cursor: pointer;
      :hover {
        opacity: 0.5;
      }
      @media (max-width: $breakMobile) {
        margin-top: 1rem;
      }
    }
    @media (max-width: $breakMobile) {
      display: block;
    }
  }
  #quizUrl {
    opacity: 0;
    pointer-events: none;
    height: 1px;
  }
  table {
    input[type="text"] {
      width: 300px;
    }
    input[type="checkbox"] {
      width: 50px;
    }
  }
  tr {
    position: relative;
  }
  .add-answer {
    float: right;
    margin-top: 1rem;
  }
  .remove-answer {
    appearance: none;
    border: 0px transparent;
    background: transparent;
    margin-bottom: -5px;
    display: inline-block;
  }
  .answer-row {
    padding: 0.5rem;
    display: grid;
    &.head {
      grid-template-columns: 1fr 5fr 1fr 1fr;
      background: transparent;
      font-size: 12px;
      font-weight: bold;
      color: $grey50;
      > div:first-child {
        justify-content: flex-start;
      }
    }
    > div {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    background: #eeeeee;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    grid-template-columns: 0.5fr 5.5fr 1fr 1fr;
  }

  .font-preview {
    margin-top: 1rem;
  }
  .rotate-icon {
    animation: spin 3s infinite linear;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.react-confirm-alert-body {
  font-family: "Lato", sans-serif;
}

@media (max-width: $breakMobile) {
  .button.button-save {
    float: none !important;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
  .button.button-preview {
    display: none;
  }
}

.button-preview {
  color: #fff;
  font-weight: bold;
  background: $grey50;
  display: inline-block;
  margin-right: 1rem;
  svg {
    display: inline-block;
    height: 14px;
    width: auto;
    margin-bottom: -2px;
    margin-right: 0.5rem;
  }
  &:hover {
    background: $grey80;
  }
}

.button-save {
  display: inline-block;
  .icon {
    display: inline;
  }
}
.button-group {
  display: flex;
  float: right;
  margin-top: 1.5rem;
}
