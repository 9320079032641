@import "../../styles/variables.scss";
#Settings {
  align-self: flex-start;
}
.settings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media (max-width: $breakMobile) {
    display: block;
  }
  label {
    margin-top: 1rem;
  }
  button {
    margin-top: 2rem;
  }
  .error {
    padding: 0.2rem;
    font-size: 12px;
  }
}
