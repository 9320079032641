@import "../../styles/variables.scss";

.StartHeader {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  z-index: 999999;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background: #eee;
  .logo-wrap {
    text-decoration: none;
    display: flex;
  }
  .menu {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      li {
        list-style: none;
        margin-left: 1rem;
        @media (max-width: $breakMobile) {
          margin-left: 0.5rem;
        }
        a {
          height: 35px;
          display: flex;
          align-items: center;
          @media (max-width: $breakMobile) {
            padding: 0 0.5rem;
            font-size: 14px;
          }
          svg {
            height: 30px;
            width: auto;
          }
          &:hover {
            @include transition;
            background-color: rgba(255, 255, 255, 0.3);
          }
          &.active {
            opacity: 0.8;
            svg {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
