h1,
h2,
h3,
h4,
h5 {
  color: $teal;
}

.subheader {
  color: $grey50;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

a {
  color: $teal;
  font-weight: bold;
}

h3 {
  color: $grey50;
  font-size: 1.5rem;
  font-weight: 300;

  margin: 0;
  margin-bottom: 2rem;
}
