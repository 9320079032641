@import "../../styles/variables.scss";

#Dashboard {
  align-self: flex-start;
  .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    a.box {
      height: 200px;
      display: flex;
      text-decoration: none;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: 0;
      > div {
        position: absolute;
        top: 1rem;
        left: 1rem;
        .sub {
          color: $grey50;
          font-weight: 300;
        }
      }
      img {
        margin-bottom: 1rem;
        max-height: 50%;
        max-width: 50%;
        height: auto;
        width: auto;
      }
      transition: all 0.2s ease;
      &:hover {
        transition: all 0.2s ease;

        transform: scale(1.05);
      }
    }
    @media (max-width: $breakMobile) {
      display: block;
      a.box {
        margin-bottom: 1rem;
      }
    }
  }
  table {
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background: #eee;
        }
        .edit {
          svg {
            height: 14px;
            width: auto;
          }
        }
      }
    }
  }
}
